import TitleCheckbox from '@/app/mypage/components/TitleCheckbox';
import TitleTexLayout from '@/app/mypage/components/TitleTexLayout';
import { myAccountQueryOptions } from '@/app/mypage/hooks/my-query';
import useUpdateMe from '@/app/mypage/hooks/useUpdateMe';
import CheckBoxWithController from '@app/mypage/components/CheckBoxWithController';
import RadioWithController from '@app/mypage/components/RadioWithController';
import DefaultLayout from '@components/layout/DefaultLayout';
import { Button } from '@components/ui/button';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_with-sidebar/settings/mypage/')({
  component: RouteComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(myAccountQueryOptions()),
});

type FormValue = {
  email: string;
  name: string;
  phone_number: string;
  tean: string;
  alert_sms: boolean;
};

function RouteComponent() {
  const { t } = useTranslation();
  const { data } = useSuspenseQuery(myAccountQueryOptions());
  const { mutate } = useUpdateMe();

  const methods = useForm<FormValue>();

  const onSubmit = () => {
    const values = methods.getValues();
    mutate({
      ...values,
    });
  };
  return (
    <DefaultLayout title={t('mypage')}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="rounded-20 shadow-basic flex flex-col gap-6 bg-white p-15"
        >
          <div className="flex flex-col gap-6">
            <TitleTexLayout
              title={t('email')}
              content={data?.email ?? ''}
              name="email"
              isEditable={false}
            />
            <TitleTexLayout
              title={t('name')}
              content={data.name ?? ''}
              name="name"
              type="text"
            />
            <TitleTexLayout
              title={t('team')}
              content={data.team ?? ''}
              name="team"
              type="text"
            />
            <TitleTexLayout
              title={t('phone')}
              content={data.phone_number ?? ''}
              name="phone_number"
              type="text"
              // 핸드폰 번호 포맷 변환
              onChange={() => {}}
            />
            <div>
              <TitleCheckbox title={t('alert_sms')}>
                <CheckBoxWithController
                  name="alert_sms"
                  checked={data?.alert_sms}
                  text={t('activated')}
                  falseValue={t('deactivated')}
                />
              </TitleCheckbox>
              <p className="text-8a mt-1 text-sm/(--spacing-30px)">
                {t('sms_notice')}
              </p>
            </div>
            <TitleCheckbox title={t('change_language')}>
              <RadioWithController />
            </TitleCheckbox>
          </div>
          <div className="flex justify-center gap-15">
            <Button variant="dialog" className="bg-7a text-base" type="button">
              {t('logout')}
            </Button>
            <Button
              variant="dialog"
              className="bg-main text-base"
              type="submit"
            >
              {t('save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </DefaultLayout>
  );
}
