import InviteUser from '@/app/member/components/InviteUser';
import MemberTable from '@/app/member/components/MemberTable';
import { membersQueryOptions } from '@/app/member/hooks/members-query';
import DefaultLayout from '@components/layout/DefaultLayout';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
export const Route = createFileRoute('/_with-sidebar/settings/members/')({
  component: RouteComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(membersQueryOptions({ page: 1, per_page: 10 })),
});

function RouteComponent() {
  const { t } = useTranslation();

  return (
    <DefaultLayout title={t('members_title')} rightItem={<InviteUser />}>
      <MemberTable />
    </DefaultLayout>
  );
}
