import api from '@services/fetch-service';
import { create } from 'zustand';

type NavType = {
  navStatus: boolean;
  setNavStatus: (v?: boolean) => void;
};
export const useNavState = create<NavType>((set) => ({
  navStatus: true,
  setNavStatus: (v?: boolean) => set(() => ({ navStatus: v })),
}));

type AuthStore = {
  accessToken: string | null;
  isLoggedIn: boolean;
  setAccessToken: (token: string | null) => void;
  checkRefreshToken: () => Promise<void>;
  clearAuth: () => void;
};
export const useAuthStore = create<AuthStore>((set) => ({
  accessToken: null,
  isLoggedIn: false,
  setAccessToken: (token) => set({ accessToken: token, isLoggedIn: !!token }),
  clearAuth: () => set({ accessToken: null, isLoggedIn: false }),

  // 새로고침 시 로그인 상태 확인
  checkRefreshToken: async () => {
    try {
      const { data } = await api.put('auth/cookie');
      set({ accessToken: data.access_token, isLoggedIn: true });
    } catch (e) {
      set({ accessToken: null, isLoggedIn: false });
    }
  },
}));

type TagsTableFilterType = {
  status_list?: string[];
  from_date?: string;
  to_date?: string;
  setup_spot_ids?: number[];
};

type TagsTableType = TagsTableFilterType & {
  setParams: (v: TagsTableFilterType) => void;
};
export const useTagsTableStore = create<TagsTableType>((set) => ({
  setParams: (params: TagsTableFilterType) => set((v) => ({ ...v, ...params })),
}));

type FirebaseStore = {
  token?: string;
  setToken: (v: string) => void;
};

export const useFirebaseStore = create<FirebaseStore>((set) => ({
  setToken: (token: string) => set(() => ({ token })),
}));

export type PayloadType = {
  address: string;
  body: string;
  created_at: string;
  location: string;
  title: string;
  type: string;
  unique_id: string;
  url: string;
  isOpen: boolean;
};

type MessageStore = {
  payload?: PayloadType;
  setPayload: (v?: PayloadType) => void;
  reset: () => void;
};

export const useMessageStore = create<MessageStore>((set) => ({
  setPayload: (payload) => set({ payload }),
  reset: () => set({ payload: undefined }),
}));
