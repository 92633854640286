import { setupSpotsInfinityOptions } from '@app/setup-spots/hooks/setupspots-query';
import ListView from '@app/tags/components/ListView';
import MapView from '@app/tags/components/MapView';
import ViewSwitch from '@app/tags/components/ViewSwitch';
import { setupSpotNfcTagQueryOptions } from '@app/tags/hooks/tags-query';
import DefaultLayout from '@components/layout/DefaultLayout';
import { createFileRoute } from '@tanstack/react-router';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_with-sidebar/tags/')({
  component: RouteComponent,
  loader: async ({ context: { queryClient } }) => {
    await Promise.all([
      queryClient.ensureQueryData(
        setupSpotNfcTagQueryOptions({
          page: 1,
          per_page: 10,
        })
      ),
      queryClient.ensureInfiniteQueryData(
        setupSpotsInfinityOptions({
          page: 1,
          per_page: 10,
        })
      ),
    ]);
  },
});

function RouteComponent() {
  const { t } = useTranslation();
  const [isList, setIsList] = useState(true);

  const renderingView = useMemo(
    () => (isList ? <ListView /> : <MapView />),
    [isList]
  );

  return (
    <DefaultLayout
      title={t('tags_title')}
      rightItem={
        <ViewSwitch
          isList={isList}
          setIsList={() => setIsList((cur) => !cur)}
        />
      }
    >
      {renderingView}
    </DefaultLayout>
  );
}
