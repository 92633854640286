import CheckBoxWithController from '@app/mypage/components/CheckBoxWithController';
import TitleCheckbox from '@app/mypage/components/TitleCheckbox';
import TitleTexLayout from '@app/mypage/components/TitleTexLayout';
import { organizationQueryOptoins } from '@app/organization/hooks/query';
import useUpdateOrganization from '@app/organization/hooks/useUpdateOrganization';
import DefaultLayout from '@components/layout/DefaultLayout';
import { Button } from '@components/ui/button';
import { useSuspenseQuery } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_with-sidebar/settings/organization/')({
  component: RouteComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(organizationQueryOptoins()),
});

function RouteComponent() {
  const { t } = useTranslation();
  const methods = useForm<{
    phone: string;
    call_alert_yn: boolean;
    sms_alert_yn: boolean;
  }>();

  const { data } = useSuspenseQuery(organizationQueryOptoins());
  const { mutate } = useUpdateOrganization();
  const onSubmit = () => {
    const value = methods.getValues();
    mutate({
      phone_number: value.phone,
      sms_alert_yn: value.sms_alert_yn,
      call_alert_yn: value.call_alert_yn,
    });
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        phone: data.phone_number,
        sms_alert_yn: data.sms_alert_yn,
        call_alert_yn: data.call_alert_yn,
      });
    }
  }, [data]);
  return (
    <DefaultLayout title={t('organization_title')}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="rounded-20 shadow-basic flex flex-col gap-6 bg-white p-15"
        >
          <TitleTexLayout
            title={t('organization_name')}
            content={data.name}
            name="organization_name"
            isEditable={false}
          />
          <TitleTexLayout
            title={t('organization_date')}
            content={data.created_at}
            name="organization_date"
            isEditable={false}
          />
          <div>
            <TitleTexLayout
              title={t('phone')}
              content={data.phone_number}
              name="organization_phone"
              type="text"
            />
            <p className="text-8a mt-1 text-sm/(--spacint-30px)">
              {t('organization_noti')}
            </p>
          </div>
          <TitleCheckbox title={t('alert_sms')}>
            <div className="flex items-center gap-4">
              <CheckBoxWithController
                name="sms_alert_yn"
                checked={data?.sms_alert_yn}
                text={t('sms')}
              />
              <CheckBoxWithController
                name="call_alert_yn"
                checked={data?.call_alert_yn}
                text={t('call')}
              />
            </div>
          </TitleCheckbox>
          <div className="flex justify-center">
            <Button
              variant="dialog"
              className="bg-main text-base"
              type="submit"
            >
              {t('save')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </DefaultLayout>
  );
}
