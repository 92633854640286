import DailyChart from '@/app/dashboard/components/DailyChart';
import DashboardCard from '@/app/dashboard/components/DashboardCard';
import DashboardLayout from '@/app/dashboard/components/DashboardLayout';
import MostReportSpot from '@/app/dashboard/components/MostReportSpot';
import ReportType from '@/app/dashboard/components/ReportType';
import ChevronIcon from '@assets/size-16/chevron-fill.svg?react';
import CalendarIcon from '@assets/size-30/calendar.svg?react';

import {
  averageTimeQueryOptions,
  countByTypeQueryOptions,
  countResolvedQueryOptions,
  dailyCountQueryOptions,
  mostSpotQueryOptions,
  reportCountQueryOptions,
} from '@/app/dashboard/hooks/dashboard-query';
import useDashboard from '@/app/dashboard/hooks/useDashboard';
import {
  cn,
  defaultDateRange,
  defaultDateRangeString,
  formatRangeToString,
} from '@/lib/utils';
import PopoverIcon from '@assets/size-24/popover.svg?react';
import { DateRangePickerButton } from '@components/common/date-range-picker/DateRangePickerButton';
import TooltipPopover from '@components/common/tooltip/TooltipPopover';
import DefaultLayout from '@components/layout/DefaultLayout';
import { Button } from '@components/ui/button';
import { createFileRoute } from '@tanstack/react-router';
import { format } from 'date-fns';
import { useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_with-sidebar/dashboard/')({
  component: RouteComponent,
  head: () => ({
    title: 'Touchsiren - Dashboard',
    meta: [],
  }),
  loader: async ({ context: { queryClient } }) => {
    const defaultDateRange = defaultDateRangeString();
    await Promise.all([
      queryClient.ensureQueryData(reportCountQueryOptions(defaultDateRange)),
      queryClient.ensureQueryData(countResolvedQueryOptions(defaultDateRange)),
      queryClient.ensureQueryData(averageTimeQueryOptions(defaultDateRange)),
      queryClient.ensureQueryData(dailyCountQueryOptions(defaultDateRange)),
      queryClient.ensureQueryData(mostSpotQueryOptions(defaultDateRange)),
      queryClient.ensureQueryData(countByTypeQueryOptions(defaultDateRange)),
    ]);
  },
});

function RouteComponent() {
  const { t } = useTranslation();
  const [date, setDate] = useState<DateRange | undefined>(defaultDateRange());
  const [
    { data: count, isLoading: countLoading },
    { data: countResolved, isLoading: countResolvedLoading },
    { data: averageTime, isLoading: averageTimeLoading },
    { data: dailyCount },
    { data: mostSpot, isLoading: mostSpotLoading },
    { data: countByCount },
  ] = useDashboard(formatRangeToString(date) ?? defaultDateRangeString());
  return (
    <DefaultLayout
      title={t('nav_dashboard')}
      rightItem={
        <DateRangePickerButton defaultDate={date} setDefaultDate={setDate}>
          <Button
            id="date"
            className={cn(
              '!rounded-30 !shadow-basic30 h-10 w-auto items-center justify-start gap-5 bg-white px-5 py-2.5 text-base/(--spacing-30px)'
            )}
          >
            <CalendarIcon className="!size-[30px]" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'yy.MM.dd')} -{' '}
                  {format(date.to, 'yy.MM.dd')}
                </>
              ) : (
                format(date.from, 'yy.MM.dd')
              )
            ) : (
              <span>Pick a date</span>
            )}
            <ChevronIcon />
          </Button>
        </DateRangePickerButton>
      }
    >
      {/* total report & processing rate && average of response time */}
      <div className="mb-10 grid grid-cols-1 gap-10 lg:grid-cols-2 xl:grid-cols-3">
        <DashboardCard
          title={t('total_report_count')}
          content={t('count_by', {
            count: count?.total_count ?? 0,
          })}
          rate={0}
          rateText={t('compared_to_previous')}
        />
        <DashboardCard
          title={t('report_process_rate')}
          content={`${countResolved ?? 0}%`}
          popover={
            <TooltipPopover
              trigger={<PopoverIcon />}
              title={t('report_rate_description')}
            />
          }
        />
        <DashboardCard
          title={t('average_response_time')}
          content={t('minutes', {
            minutes: averageTime?.average_minute ?? 0,
          })}
          rate={averageTime?.increase_rate}
          popover={
            <TooltipPopover
              trigger={<PopoverIcon />}
              title={t('average_response_description')}
            />
          }
        />
      </div>

      <DailyChart data={dailyCount ?? []} total={count?.total_count ?? 0} />

      <div className="mt-10 flex flex-wrap gap-10 xl:flex-nowrap">
        <DashboardLayout title={t('most_report_spot')}>
          <MostReportSpot data={mostSpot ?? []} />
        </DashboardLayout>
        <DashboardLayout title={t('report_type')}>
          <ReportType data={countByCount ?? []} />
        </DashboardLayout>
      </div>
    </DefaultLayout>
  );
}
