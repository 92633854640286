import RegisterSetupSpot from '@app/setup-spots/components/RegisterSetupSpot';
import SetupSpotsTable from '@app/setup-spots/components/SetupSpotsTable';
import { setupSpotQueryOptions } from '@app/setup-spots/hooks/setupspots-query';

import RegisterIcon from '@assets/size-24/register.svg?react';
import DefaultLayout from '@components/layout/DefaultLayout';
import { Button } from '@components/ui/button';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_with-sidebar/settings/setup-spots/')({
  component: RouteComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(
      setupSpotQueryOptions({
        page: 1,
        per_page: 10,
      })
    ),
});

function RouteComponent() {
  const { t } = useTranslation();

  return (
    <DefaultLayout
      title={t('setup_spots_install_title')}
      rightItem={
        <RegisterSetupSpot>
          <Button variant="iconText" className="bg-main capitalize">
            <RegisterIcon />
            {t('register')}
          </Button>
        </RegisterSetupSpot>
      }
    >
      <SetupSpotsTable />
    </DefaultLayout>
  );
}
