import UserHeader from '@components/common/header/UserHeader';
import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/user')({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <div className="bg-bg h-screen">
      <UserHeader />

      <div className="mt-15 w-full px-5">
        <div className="shadow-basic rounded-20 mx-auto flex max-w-[1200px] flex-col items-center bg-white py-15">
          <div className="w-full max-w-[540px] px-5">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
