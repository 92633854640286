import axios from 'axios';

export const access_token = '';

const api = axios.create({
  baseURL: import.meta.env.VITE_BASE_API,
  timeout: 30000,
  withCredentials: true,
});

const getAuthorization = (): string => {
  // const accessToken = useAuthStore.getState().accessToken;
  const accessToken =
    'eyJhbGciOiJBMTI4R0NNS1ciLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiaXYiOiJSc1BjQV9Kc0JlclVfclIzIiwidGFnIjoiN05TY2dMdUIzN2k1Z05BZHYyeVdRUSJ9.yIH732nMCNOUSyilL152JyyoXvQZ4VCcs3gTqiMJQr8.4JOzIeEKTjsIIdfOWmY6-Q.g4A57chgUPvArdydT7a2WOzoHsmjBc6ol6ArcXs-s2Ot5BqUkiWEkXxg-980bfJugG5LNWGCIR4o9InRNm9NutPm1_3cWBDfNl6y4bVzHBM.ZHRxcsbrvbjjWshw50IhYw';
  return `Bearer ${accessToken}`;
};

const getTimezone = () => {
  // useAccountStore.getState().account?.timezone ??
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return userTimezone;
};

api.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] = getAuthorization();
    config.headers['timezone'] = getTimezone();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response, // 성공 응답 그대로 반환
  async (error) => {
    return Promise.reject(error); // 다른 에러는 그대로 반환
  }
);

export default api;
