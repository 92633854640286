import TableData from '@/app/report/components/TableData';

import ReportFilters from '@/app/report/components/ReportFilters';
import { reportsQueryOptions } from '@/app/report/hooks/report-query';
import useReports from '@/app/report/hooks/useReports';
import useTableState from '@/hooks/table/useTableState';
import { defaultDateRangeString } from '@/lib/utils';
import ReportDetailDialog from '@app/report/components/ReportDetailDialog';
import Loader from '@components/common/Loader/LoadingSpinner';
import DefaultLayout from '@components/layout/DefaultLayout';
import { ReportsQuery } from '@services/api-client';
import { createFileRoute, useSearch } from '@tanstack/react-router';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

export type ReportFormValue = {
  setup_spot_ids?: number[];
  nfc_tag_id?: number;
  status_list?: string[];

  from_date?: string;
  to_date?: string;
};

const searchSchema = z.object({
  id: z.number().optional(),
});

export const Route = createFileRoute('/_with-sidebar/report/')({
  component: RouteComponent,
  validateSearch: searchSchema,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(
      reportsQueryOptions({
        page: 1,
        per_page: 10,
        ...defaultDateRangeString(),
      })
    ),
});

function RouteComponent() {
  const { t } = useTranslation();
  const { pagination, setPagination } = useTableState();
  const { id } = useSearch({
    strict: false,
  });

  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<ReportsQuery>({
    page: pagination.pageIndex + 1,
    per_page: 10,
    ...defaultDateRangeString(),
  });

  const { data, isFetching } = useReports(params);
  const handleParams = useCallback((v: ReportFormValue) => {
    setParams((cur) => ({ ...cur, ...v }));
  }, []);

  useEffect(() => {
    setParams((cur) => ({
      ...cur,
      page: pagination.pageIndex + 1,
      per_page: pagination.pageSize,
    }));
  }, [pagination]);
  useEffect(() => {
    if (id) {
      setOpen(true);
    }
  }, [id]);
  return (
    <>
      {isFetching && <Loader />}
      <DefaultLayout title={t('nav_report')}>
        <ReportFilters handleParams={handleParams} />

        <TableData
          data={data?.reports ?? []}
          total={data?.count ?? 0}
          pagination={pagination}
          setPagination={setPagination}
        />
      </DefaultLayout>
      {id && <ReportDetailDialog id={id} open={open} setOpen={setOpen} />}
    </>
  );
}
