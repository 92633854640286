import { useFirebaseStore, useMessageStore } from '@provider/context';
import api from '@services/fetch-service';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { toast } from 'sonner';
import { Device } from './models';

const firebaseConfig = {
  apiKey: 'AIzaSyDt04YANhM5TMcevdGC2w6zhN3DST9PL_c',
  authDomain: 'touch-siren-410006.firebaseapp.com',
  projectId: 'touch-siren-410006',
  storageBucket: 'touch-siren-410006.appspot.com',
  messagingSenderId: '550167052794',
  appId: '1:550167052794:web:db6641a1a6ff663e16a443',
  measurementId: 'G-8JMTCR6PVE',
};

const app = initializeApp(firebaseConfig, 'touchsiren-push');

export const messaging = getMessaging(app);

export const requestPermission = () => {
  void Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      getToken(messaging, {
        vapidKey: import.meta.env.VITE_BASSE_FIREBASE_MESSAGE_VAPID_KEY,
      })
        .then(async (currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            const { data }: { data: Device } = await api.post(
              `notification/device`,
              {
                token: currentToken,
              }
            );
            useFirebaseStore.getState().setToken(data.token);
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            );
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });

      //
    } else if (permission === 'denied') {
      toast.info('Denied Alarm Permission', {
        description:
          'you need to access browser alarm permission to receive the emegency alarm on browser',
        duration: 10000,
        position: 'top-center',
      });
    }
  });
};

export const handleMessage = () => {
  onMessage(messaging, (payload) => {
    useMessageStore.getState().setPayload({
      address: payload.data?.address || '',
      body: payload.data?.body || '',
      created_at: payload.data?.created_at || '',
      location: payload.data?.location || '',
      title: payload.data?.title || '',
      type: payload.data?.type || '',
      unique_id: payload.data?.unique_id || '',
      url: payload.data?.url || '',
      isOpen: true,
    });
  });
};
