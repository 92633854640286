import { useTheme } from 'next-themes';
import { Toaster as Sonner } from 'sonner';

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = 'system' } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps['theme']}
      className="toaster group"
      toastOptions={{
        classNames: {
          icon: '!size-[34px]',
          toast:
            'group toast group-[.toaster]:!bg-[rgba(0,0,0,0.8)] group-[.toaster]:!text-white !border-transparent !rounded-20',
          description: 'group-[.toast]:!text-white',
          actionButton:
            'group-[.toast]:bg-black group-[.toast]:text-neutral-50',
          cancelButton:
            'group-[.toast]:bg-black group-[.toast]:text-neutral-500',
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
