import UserTextBox from '@/app/user/components/UserTextBox';
import useLogin from '@/app/user/hooks/useLogin';
import { loginSchema } from '@/app/user/schema';
import Button from '@components/basic/buttons/Button';
import Loader from '@components/common/Loader/LoadingSpinner';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute, Link } from '@tanstack/react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/user/login/')({
  component: RouteComponent,
});

function RouteComponent() {
  const { t } = useTranslation();
  const methods = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });
  const { mutate, isPending } = useLogin();
  const isValid = methods.formState.isValid;

  const handleLogin = () => {
    const values = methods.getValues();

    mutate(values);
  };
  return (
    <>
      {isPending && <Loader />}
      <h2 className="text-title mb-15 font-bold">{t('login')}</h2>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleLogin)}
          className="flex flex-col gap-1"
        >
          <UserTextBox
            label={t('email')}
            placeholder="email@address.com"
            name="email"
            type="email"
          />
          <UserTextBox
            label={t('password')}
            placeholder={t('password_placeholder')}
            name="password"
            type="password"
          />

          <div className="mt-9">
            <Button className="mb-6" disabled={!isValid}>
              {t('login')}
            </Button>
            <Link to="/user/password">
              <Button className="bg-7a">{t('reset_password')}</Button>
            </Link>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
