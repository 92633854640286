import {
  Account,
  AccountInviteLog,
  AverageResponseTimeRes,
  CreateInitialAccountRequest,
  CreateReportType,
  CreateSetupSpot,
  DailyReportCountRes,
  FullToken,
  InviteAccountRequest,
  ListNfcRes,
  ListReportsRes,
  MostReportedSpotRes,
  MostReportedTypeRes,
  NfcTag,
  Notification,
  Organization,
  ReadNotifications,
  RegisterAccountRequest,
  Report,
  ReportCountRes,
  ReportMemo,
  ReportType,
  SaveReportMemo,
  SetupSpot,
  UpdateAccountRequest,
  UpdateMyAccountRequest,
  UpdateMyOrgRequest,
  UpdateNfcTag,
  UpdateReport,
  UpdateReportTypeReq,
  UpdateSetupSpot,
} from '@/models';
import { stringify } from 'qs';
import api from './fetch-service';
type DateParams = {
  from_date: string;
  to_date: string;
};
export const getStatReportCount = async (
  params: DateParams
): Promise<ReportCountRes> => {
  const { data } = await api.get(`stat/report/count?${stringify(params)}`);
  return data;
};
export const getCountResolved = async (params: DateParams): Promise<number> => {
  const { data } = await api.get(
    `stat/report/count/resolved?${stringify(params)}`
  );
  return data;
};
export const getAverageTime = async (
  params: DateParams
): Promise<AverageResponseTimeRes> => {
  const { data } = await api.get(
    `stat/report/average-response-time?${stringify(params)}`
  );
  return data;
};
export const getDailyCount = async (
  params: DateParams
): Promise<DailyReportCountRes[]> => {
  const { data } = await api.get(
    `stat/report/daily-count?${stringify(params)}`
  );
  return data;
};
export const getMostSpot = async (
  params: DateParams
): Promise<MostReportedSpotRes[]> => {
  const { data } = await api.get(
    `stat/report/most-reported-spots?${stringify(params)}`
  );
  return data;
};
export const getCountByCount = async (
  params: DateParams
): Promise<MostReportedTypeRes[]> => {
  const { data } = await api.get(
    `stat/report/count-by-type?${stringify(params)}`
  );
  return data;
};

/* ---------------------------------- AUTH ---------------------------------- */

export const createAuth = async ({
  firebase_token,
}: {
  firebase_token: string;
}): Promise<FullToken> => {
  const { data } = await api.post('auth', {
    firebase_token: firebase_token,
  });

  return data;
};

export const createRegister = async (
  body: RegisterAccountRequest
): Promise<Account> => {
  const { data } = await api.post('account/register', {
    ...body,
  });
  return data;
};

export const createInitialAccount = async (
  body: CreateInitialAccountRequest
): Promise<Account> => {
  const { data } = await api.post(`account/initial`, {
    ...body,
  });
  return data;
};

export const getAccountMe = async (): Promise<Account> => {
  const { data } = await api.get('account/me');
  return data;
};

export const updateAccountMe = async (
  body: UpdateMyAccountRequest
): Promise<Account> => {
  const { data } = await api.put(`account/me`, {
    ...body,
  });
  return data;
};

export const getOrganization = async (): Promise<Organization> => {
  const { data } = await api.get('organization/my');
  return data;
};

export const updateOrganization = async (
  body: UpdateMyOrgRequest
): Promise<Organization> => {
  const { data } = await api.put(`organization/my`, {
    ...body,
  });
  return data;
};

export const getAccounts = async (
  page: number,
  perPage: number
): Promise<Account[]> => {
  const { data } = await api.get(
    `accounts?${stringify({
      page,
      per_page: perPage,
    })}`
  );
  return data;
};

export const getNotifications = async (params: {
  page: number;
  per_page: number;
}): Promise<Notification[]> => {
  const { data } = await api.get(`notifications?${stringify(params)}`);
  return data;
};

export const readNotifications = async (
  params: ReadNotifications
): Promise<Notification[]> => {
  const { data } = await api.put(`notifications/read`, {
    ...params,
  });
  return data;
};

/* --------------------------------- reports -------------------------------- */
export type ReportsQuery = {
  page: number;
  per_page: number;
  from_date?: string;
  to_date?: string;
  setup_spot_ids?: number[];
  nfc_tag_id?: number;
  status_list?: string[];
};

export const getReports = async (
  query: ReportsQuery
): Promise<ListReportsRes> => {
  const { data } = await api.get(
    `reports?${stringify(query, {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })}`
  );
  return data;
};

export const getReport = async (id: number): Promise<Report> => {
  const { data } = await api.get(`report/${id}`);
  return data;
};
export const updateReport = async (
  id: number,
  body: UpdateReport
): Promise<Report> => {
  const { data } = await api.put(`report/${id}`, {
    ...body,
  });
  return data;
};

export const getReportTypes = async (): Promise<ReportType[]> => {
  const { data } = await api.get(`report/types`);
  return data;
};

export const createReportTypes = async (
  body: CreateReportType
): Promise<ReportType> => {
  const { data } = await api.post(`report/type`, {
    ...body,
  });
  return data;
};

export const updateReportTypes = async (
  body: UpdateReportTypeReq
): Promise<ReportType[]> => {
  const { data } = await api.put(`report/type`, [body]);
  return data;
};

export const deleteReportType = async (id: number) => {
  const { data } = await api.delete(`report/type/${id}`);
  return data;
};

export const createReportMemo = async (
  params: SaveReportMemo,
  id: number
): Promise<ReportMemo> => {
  const { data } = await api.post(`report/${id}/memo`, {
    ...params,
  });
  return data;
};

export const updateReportMemo = async (
  params: SaveReportMemo,
  id: number,
  memo_id: number
): Promise<ReportMemo> => {
  const { data } = await api.put(`report/${id}/memo/${memo_id}`, {
    ...params,
  });
  return data;
};
export const deleteReportMemo = async (
  id: number,
  memo_id: number
): Promise<ReportMemo> => {
  const { data } = await api.delete(`report/${id}/memo/${memo_id}`);
  return data;
};

/* --------------------------------- members -------------------------------- */

export type MembersQuery = {
  page: number;
  per_page: number;
};
export const getMembers = async (query: MembersQuery): Promise<Account[]> => {
  const { data } = await api.get(`accounts?${stringify(query)}`);
  return data;
};

export const createInvite = async (
  body: InviteAccountRequest
): Promise<AccountInviteLog> => {
  const { data } = await api.post(`account/invite`, {
    ...body,
  });
  return data;
};

export const updateAccount = async (
  id: number,
  body: UpdateAccountRequest
): Promise<Account> => {
  const { data } = await api.put(`account/${id}`, {
    ...body,
  });
  return data;
};
export const deleteAccount = async (id: number): Promise<Account> => {
  const { data } = await api.delete(`account/${id}`);
  return data;
};

/* ---------------------------------- setup --------------------------------- */
export type SetupSpotsOrgQuery = {
  page: number;
  per_page: number;
  lat?: number;
  lng?: number;
};
export const getSetupSpotsOrg = async (
  query: SetupSpotsOrgQuery
): Promise<SetupSpot[]> => {
  const { data } = await api.get(`setup_spots/org?${stringify(query)}`);
  return data;
};

export type SetupSpotNfcTagsQuery = {
  page: number;
  per_page: number;
  from_date?: string;
  to_date?: string;
  status_list?: string[];
  nfc_tag_id?: string;
  setup_spot_ids?: number[];
};
export const getSetupSpotTags = async (
  query: SetupSpotNfcTagsQuery
): Promise<ListNfcRes> => {
  const { data } = await api.get(
    `setup_spot/nfc_tags?${stringify(query, {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })}`
  );
  return data;
};

export const createSetupSpot = async (
  body: CreateSetupSpot
): Promise<SetupSpot> => {
  const { data } = await api.post(`setup_spot`, {
    ...body,
  });
  return data;
};

export const updateSetupSpot = async (
  id: number,
  body: UpdateSetupSpot
): Promise<SetupSpot> => {
  const { data } = await api.put(`setup_spot/${id}`, {
    ...body,
  });
  return data;
};
export const deleteSetupSpot = async (id: number): Promise<SetupSpot> => {
  const { data } = await api.delete(`setup_spot/${id}`);
  return data;
};

export const updateSetupSpotTags = async (
  id: number,
  tid: number,
  body: UpdateNfcTag
): Promise<NfcTag> => {
  const { data } = await api.put(`setup_spot/${id}/nfc_tag/${tid}`, {
    ...body,
  });
  return data;
};
