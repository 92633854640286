import { clsx, type ClassValue } from 'clsx';
import { format, subDays } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const defaultDateRangeString = () => {
  const now = new Date();
  const startOfWeek = subDays(now, 6); // 7일 전

  return {
    from_date: format(startOfWeek, 'yyyy-MM-dd 00:00:00'),
    to_date: format(now, 'yyyy-MM-dd 23:59:59'),
  };
};

export const defaultDateRange = () => {
  const now = new Date();
  return {
    from: subDays(now, 6),
    to: now,
  };
};

export const formatRangeToString = (date?: DateRange) => {
  if (date) {
    if (date.from && date.to) {
      return {
        from_date: format(date.from, 'yyyy-MM-dd 00:00:00'),
        to_date: format(date.to, 'yyyy-MM-dd 23:59:59'),
      };
    }
  }
};

export const formatRangeSearch = (date?: DateRange) => {
  if (date) {
    if (date.from && date.to) {
      return `${format(date.from, 'yyyy.MM.dd')} - ${format(date.to, 'yyyy.MM.dd')}`;
    }
  }
  return '';
};

export const formatDot = (date?: string) => {
  if (!date) return '';
  return format(new Date(date), 'yyyy.MM.dd');
};

export const formatDotWithTime = (date?: string) => {
  if (!date) return '';

  return format(new Date(date), 'yy.MM.dd hh:mm:ss');
};

export const formatTimeDifference = (date: string) => {
  const dateTime = new Date(date);
  const now = new Date();
  if (isNaN(dateTime.getTime())) return '-';
  const diffInMilliseconds = Math.abs(now.getTime() - dateTime.getTime());

  // 변환을 위한 단위
  const secondsInMinute = 60;
  const secondsInHour = 60 * 60;
  const secondsInDay = 60 * 60 * 24;
  const secondsInMonth = 60 * 60 * 24 * 30; // 대략 30일로 계산
  const secondsInYear = 60 * 60 * 24 * 365; // 대략 365일로 계산
  // 초 단위로 변환
  const remainingSeconds = Math.floor(diffInMilliseconds / 1000);

  // 1분 이하의 초 단위는 1분으로
  if (remainingSeconds < secondsInMinute) {
    return '1 min ago';
  }

  // 분 단위
  if (remainingSeconds < secondsInHour) {
    const minutes = Math.floor(remainingSeconds / secondsInMinute);
    return `${minutes} min ago `;
  }

  // 시간 단위
  if (remainingSeconds < secondsInDay) {
    const hours = Math.floor(remainingSeconds / secondsInHour);
    return `${hours} hours ago`;
  }

  // 일 단위
  if (remainingSeconds < secondsInMonth) {
    const days = Math.floor(remainingSeconds / secondsInDay);
    return `${days} days ago`;
  }

  // 월 단위
  if (remainingSeconds < secondsInYear) {
    const months = Math.floor(remainingSeconds / secondsInMonth);
    return `${months} months ago`;
  }

  // 년 단위
  const years = Math.floor(remainingSeconds / secondsInYear);
  return `${years} years ago`;
};

export const pickChangedValues = (
  original: Record<string, unknown>,
  updated: Record<string, unknown>
) => {
  return Object.keys(updated).reduce(
    (acc, key) => {
      if (original[key] !== updated[key]) {
        acc[key] = updated[key];
      }
      return acc;
    },
    {} as Record<string, unknown>
  );
};
