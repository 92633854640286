import ErrorIcon from '@assets/size-24/error.svg?react';
import InfoIcon from '@assets/size-24/info.svg?react';
import SuccessIcon from '@assets/size-24/success.svg?react';
import { Toaster } from '@components/ui/sonner';
import type { QueryClient } from '@tanstack/react-query';
import {
  createRootRouteWithContext,
  Link,
  Outlet,
} from '@tanstack/react-router';

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: RootComponent,
  head: () => ({
    meta: [
      {
        title: 'Touchsiren admin',
      },
      {
        name: 'touchsiren admin',
        content: 'emergency report solution, touchsiren admin',
      },
    ],
    links: [
      {
        rel: 'icon',
        href: '/favicon.ico',
      },
    ],
  }),
  notFoundComponent: () => {
    return (
      <div>
        <p>This is the notFoundComponent configured on root route</p>
        <Link to="/">Start Over</Link>
      </div>
    );
  },
});

function RootComponent() {
  return (
    <>
      <Outlet />

      <Toaster
        icons={{
          success: <SuccessIcon />,
          error: <ErrorIcon />,
          info: <InfoIcon />,
        }}
      />
    </>
  );
}
