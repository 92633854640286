import {
  getAverageTime,
  getCountByCount,
  getCountResolved,
  getDailyCount,
  getMostSpot,
  getStatReportCount,
} from '@services/api-client';
import { queryOptions } from '@tanstack/react-query';

// ✅ 신고 개수 조회
export const reportCountQueryOptions = (params: {
  from_date: string;
  to_date: string;
}) =>
  queryOptions({
    queryKey: ['stat', 'report-count', params],
    queryFn: () => getStatReportCount(params),
  });

// ✅ 해결된 신고 개수 조회
export const countResolvedQueryOptions = (params: {
  from_date: string;
  to_date: string;
}) =>
  queryOptions({
    queryKey: ['stat', 'count-resolved', params],
    queryFn: () => getCountResolved(params),
  });

// ✅ 평균 응답 시간 조회
export const averageTimeQueryOptions = (params: {
  from_date: string;
  to_date: string;
}) =>
  queryOptions({
    queryKey: ['stat', 'average-response-time', params],
    queryFn: () => getAverageTime(params),
  });

// ✅ 일별 신고 개수 조회
export const dailyCountQueryOptions = (params: {
  from_date: string;
  to_date: string;
}) =>
  queryOptions({
    queryKey: ['stat', 'daily-report-count', params],
    queryFn: () => getDailyCount(params),
  });

// ✅ 가장 많이 신고된 장소 조회
export const mostSpotQueryOptions = (params: {
  from_date: string;
  to_date: string;
}) =>
  queryOptions({
    queryKey: ['stat', 'most-reported-spots', params],
    queryFn: () => getMostSpot(params),
  });

// ✅ 신고 유형별 개수 조회
export const countByTypeQueryOptions = (params: {
  from_date: string;
  to_date: string;
}) =>
  queryOptions({
    queryKey: ['stat', 'count-by-type', params],
    queryFn: () => getCountByCount(params),
  });
