/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as UserImport } from './routes/user';
import { Route as WithSidebarImport } from './routes/_with-sidebar';
import { Route as IndexImport } from './routes/index';
import { Route as UserRegisterIndexImport } from './routes/user/register/index';
import { Route as UserPasswordIndexImport } from './routes/user/password/index';
import { Route as UserLoginIndexImport } from './routes/user/login/index';
import { Route as UserJoinIndexImport } from './routes/user/join/index';
import { Route as WithSidebarTagsIndexImport } from './routes/_with-sidebar/tags/index';
import { Route as WithSidebarReportIndexImport } from './routes/_with-sidebar/report/index';
import { Route as WithSidebarDashboardIndexImport } from './routes/_with-sidebar/dashboard/index';
import { Route as WithSidebarSettingsSetupSpotsIndexImport } from './routes/_with-sidebar/settings/setup-spots/index';
import { Route as WithSidebarSettingsReportTypesIndexImport } from './routes/_with-sidebar/settings/report-types/index';
import { Route as WithSidebarSettingsOrganizationIndexImport } from './routes/_with-sidebar/settings/organization/index';
import { Route as WithSidebarSettingsMypageIndexImport } from './routes/_with-sidebar/settings/mypage/index';
import { Route as WithSidebarSettingsMembersIndexImport } from './routes/_with-sidebar/settings/members/index';

// Create/Update Routes

const UserRoute = UserImport.update({
  id: '/user',
  path: '/user',
  getParentRoute: () => rootRoute,
} as any);

const WithSidebarRoute = WithSidebarImport.update({
  id: '/_with-sidebar',
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any);

const UserRegisterIndexRoute = UserRegisterIndexImport.update({
  id: '/register/',
  path: '/register/',
  getParentRoute: () => UserRoute,
} as any);

const UserPasswordIndexRoute = UserPasswordIndexImport.update({
  id: '/password/',
  path: '/password/',
  getParentRoute: () => UserRoute,
} as any);

const UserLoginIndexRoute = UserLoginIndexImport.update({
  id: '/login/',
  path: '/login/',
  getParentRoute: () => UserRoute,
} as any);

const UserJoinIndexRoute = UserJoinIndexImport.update({
  id: '/join/',
  path: '/join/',
  getParentRoute: () => UserRoute,
} as any);

const WithSidebarTagsIndexRoute = WithSidebarTagsIndexImport.update({
  id: '/tags/',
  path: '/tags/',
  getParentRoute: () => WithSidebarRoute,
} as any);

const WithSidebarReportIndexRoute = WithSidebarReportIndexImport.update({
  id: '/report/',
  path: '/report/',
  getParentRoute: () => WithSidebarRoute,
} as any);

const WithSidebarDashboardIndexRoute = WithSidebarDashboardIndexImport.update({
  id: '/dashboard/',
  path: '/dashboard/',
  getParentRoute: () => WithSidebarRoute,
} as any);

const WithSidebarSettingsSetupSpotsIndexRoute =
  WithSidebarSettingsSetupSpotsIndexImport.update({
    id: '/settings/setup-spots/',
    path: '/settings/setup-spots/',
    getParentRoute: () => WithSidebarRoute,
  } as any);

const WithSidebarSettingsReportTypesIndexRoute =
  WithSidebarSettingsReportTypesIndexImport.update({
    id: '/settings/report-types/',
    path: '/settings/report-types/',
    getParentRoute: () => WithSidebarRoute,
  } as any);

const WithSidebarSettingsOrganizationIndexRoute =
  WithSidebarSettingsOrganizationIndexImport.update({
    id: '/settings/organization/',
    path: '/settings/organization/',
    getParentRoute: () => WithSidebarRoute,
  } as any);

const WithSidebarSettingsMypageIndexRoute =
  WithSidebarSettingsMypageIndexImport.update({
    id: '/settings/mypage/',
    path: '/settings/mypage/',
    getParentRoute: () => WithSidebarRoute,
  } as any);

const WithSidebarSettingsMembersIndexRoute =
  WithSidebarSettingsMembersIndexImport.update({
    id: '/settings/members/',
    path: '/settings/members/',
    getParentRoute: () => WithSidebarRoute,
  } as any);

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    '/_with-sidebar': {
      id: '/_with-sidebar';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof WithSidebarImport;
      parentRoute: typeof rootRoute;
    };
    '/user': {
      id: '/user';
      path: '/user';
      fullPath: '/user';
      preLoaderRoute: typeof UserImport;
      parentRoute: typeof rootRoute;
    };
    '/_with-sidebar/dashboard/': {
      id: '/_with-sidebar/dashboard/';
      path: '/dashboard';
      fullPath: '/dashboard';
      preLoaderRoute: typeof WithSidebarDashboardIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/_with-sidebar/report/': {
      id: '/_with-sidebar/report/';
      path: '/report';
      fullPath: '/report';
      preLoaderRoute: typeof WithSidebarReportIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/_with-sidebar/tags/': {
      id: '/_with-sidebar/tags/';
      path: '/tags';
      fullPath: '/tags';
      preLoaderRoute: typeof WithSidebarTagsIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/user/join/': {
      id: '/user/join/';
      path: '/join';
      fullPath: '/user/join';
      preLoaderRoute: typeof UserJoinIndexImport;
      parentRoute: typeof UserImport;
    };
    '/user/login/': {
      id: '/user/login/';
      path: '/login';
      fullPath: '/user/login';
      preLoaderRoute: typeof UserLoginIndexImport;
      parentRoute: typeof UserImport;
    };
    '/user/password/': {
      id: '/user/password/';
      path: '/password';
      fullPath: '/user/password';
      preLoaderRoute: typeof UserPasswordIndexImport;
      parentRoute: typeof UserImport;
    };
    '/user/register/': {
      id: '/user/register/';
      path: '/register';
      fullPath: '/user/register';
      preLoaderRoute: typeof UserRegisterIndexImport;
      parentRoute: typeof UserImport;
    };
    '/_with-sidebar/settings/members/': {
      id: '/_with-sidebar/settings/members/';
      path: '/settings/members';
      fullPath: '/settings/members';
      preLoaderRoute: typeof WithSidebarSettingsMembersIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/_with-sidebar/settings/mypage/': {
      id: '/_with-sidebar/settings/mypage/';
      path: '/settings/mypage';
      fullPath: '/settings/mypage';
      preLoaderRoute: typeof WithSidebarSettingsMypageIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/_with-sidebar/settings/organization/': {
      id: '/_with-sidebar/settings/organization/';
      path: '/settings/organization';
      fullPath: '/settings/organization';
      preLoaderRoute: typeof WithSidebarSettingsOrganizationIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/_with-sidebar/settings/report-types/': {
      id: '/_with-sidebar/settings/report-types/';
      path: '/settings/report-types';
      fullPath: '/settings/report-types';
      preLoaderRoute: typeof WithSidebarSettingsReportTypesIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
    '/_with-sidebar/settings/setup-spots/': {
      id: '/_with-sidebar/settings/setup-spots/';
      path: '/settings/setup-spots';
      fullPath: '/settings/setup-spots';
      preLoaderRoute: typeof WithSidebarSettingsSetupSpotsIndexImport;
      parentRoute: typeof WithSidebarImport;
    };
  }
}

// Create and export the route tree

interface WithSidebarRouteChildren {
  WithSidebarDashboardIndexRoute: typeof WithSidebarDashboardIndexRoute;
  WithSidebarReportIndexRoute: typeof WithSidebarReportIndexRoute;
  WithSidebarTagsIndexRoute: typeof WithSidebarTagsIndexRoute;
  WithSidebarSettingsMembersIndexRoute: typeof WithSidebarSettingsMembersIndexRoute;
  WithSidebarSettingsMypageIndexRoute: typeof WithSidebarSettingsMypageIndexRoute;
  WithSidebarSettingsOrganizationIndexRoute: typeof WithSidebarSettingsOrganizationIndexRoute;
  WithSidebarSettingsReportTypesIndexRoute: typeof WithSidebarSettingsReportTypesIndexRoute;
  WithSidebarSettingsSetupSpotsIndexRoute: typeof WithSidebarSettingsSetupSpotsIndexRoute;
}

const WithSidebarRouteChildren: WithSidebarRouteChildren = {
  WithSidebarDashboardIndexRoute: WithSidebarDashboardIndexRoute,
  WithSidebarReportIndexRoute: WithSidebarReportIndexRoute,
  WithSidebarTagsIndexRoute: WithSidebarTagsIndexRoute,
  WithSidebarSettingsMembersIndexRoute: WithSidebarSettingsMembersIndexRoute,
  WithSidebarSettingsMypageIndexRoute: WithSidebarSettingsMypageIndexRoute,
  WithSidebarSettingsOrganizationIndexRoute:
    WithSidebarSettingsOrganizationIndexRoute,
  WithSidebarSettingsReportTypesIndexRoute:
    WithSidebarSettingsReportTypesIndexRoute,
  WithSidebarSettingsSetupSpotsIndexRoute:
    WithSidebarSettingsSetupSpotsIndexRoute,
};

const WithSidebarRouteWithChildren = WithSidebarRoute._addFileChildren(
  WithSidebarRouteChildren
);

interface UserRouteChildren {
  UserJoinIndexRoute: typeof UserJoinIndexRoute;
  UserLoginIndexRoute: typeof UserLoginIndexRoute;
  UserPasswordIndexRoute: typeof UserPasswordIndexRoute;
  UserRegisterIndexRoute: typeof UserRegisterIndexRoute;
}

const UserRouteChildren: UserRouteChildren = {
  UserJoinIndexRoute: UserJoinIndexRoute,
  UserLoginIndexRoute: UserLoginIndexRoute,
  UserPasswordIndexRoute: UserPasswordIndexRoute,
  UserRegisterIndexRoute: UserRegisterIndexRoute,
};

const UserRouteWithChildren = UserRoute._addFileChildren(UserRouteChildren);

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute;
  '': typeof WithSidebarRouteWithChildren;
  '/user': typeof UserRouteWithChildren;
  '/dashboard': typeof WithSidebarDashboardIndexRoute;
  '/report': typeof WithSidebarReportIndexRoute;
  '/tags': typeof WithSidebarTagsIndexRoute;
  '/user/join': typeof UserJoinIndexRoute;
  '/user/login': typeof UserLoginIndexRoute;
  '/user/password': typeof UserPasswordIndexRoute;
  '/user/register': typeof UserRegisterIndexRoute;
  '/settings/members': typeof WithSidebarSettingsMembersIndexRoute;
  '/settings/mypage': typeof WithSidebarSettingsMypageIndexRoute;
  '/settings/organization': typeof WithSidebarSettingsOrganizationIndexRoute;
  '/settings/report-types': typeof WithSidebarSettingsReportTypesIndexRoute;
  '/settings/setup-spots': typeof WithSidebarSettingsSetupSpotsIndexRoute;
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute;
  '': typeof WithSidebarRouteWithChildren;
  '/user': typeof UserRouteWithChildren;
  '/dashboard': typeof WithSidebarDashboardIndexRoute;
  '/report': typeof WithSidebarReportIndexRoute;
  '/tags': typeof WithSidebarTagsIndexRoute;
  '/user/join': typeof UserJoinIndexRoute;
  '/user/login': typeof UserLoginIndexRoute;
  '/user/password': typeof UserPasswordIndexRoute;
  '/user/register': typeof UserRegisterIndexRoute;
  '/settings/members': typeof WithSidebarSettingsMembersIndexRoute;
  '/settings/mypage': typeof WithSidebarSettingsMypageIndexRoute;
  '/settings/organization': typeof WithSidebarSettingsOrganizationIndexRoute;
  '/settings/report-types': typeof WithSidebarSettingsReportTypesIndexRoute;
  '/settings/setup-spots': typeof WithSidebarSettingsSetupSpotsIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/': typeof IndexRoute;
  '/_with-sidebar': typeof WithSidebarRouteWithChildren;
  '/user': typeof UserRouteWithChildren;
  '/_with-sidebar/dashboard/': typeof WithSidebarDashboardIndexRoute;
  '/_with-sidebar/report/': typeof WithSidebarReportIndexRoute;
  '/_with-sidebar/tags/': typeof WithSidebarTagsIndexRoute;
  '/user/join/': typeof UserJoinIndexRoute;
  '/user/login/': typeof UserLoginIndexRoute;
  '/user/password/': typeof UserPasswordIndexRoute;
  '/user/register/': typeof UserRegisterIndexRoute;
  '/_with-sidebar/settings/members/': typeof WithSidebarSettingsMembersIndexRoute;
  '/_with-sidebar/settings/mypage/': typeof WithSidebarSettingsMypageIndexRoute;
  '/_with-sidebar/settings/organization/': typeof WithSidebarSettingsOrganizationIndexRoute;
  '/_with-sidebar/settings/report-types/': typeof WithSidebarSettingsReportTypesIndexRoute;
  '/_with-sidebar/settings/setup-spots/': typeof WithSidebarSettingsSetupSpotsIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | '/'
    | ''
    | '/user'
    | '/dashboard'
    | '/report'
    | '/tags'
    | '/user/join'
    | '/user/login'
    | '/user/password'
    | '/user/register'
    | '/settings/members'
    | '/settings/mypage'
    | '/settings/organization'
    | '/settings/report-types'
    | '/settings/setup-spots';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | '/'
    | ''
    | '/user'
    | '/dashboard'
    | '/report'
    | '/tags'
    | '/user/join'
    | '/user/login'
    | '/user/password'
    | '/user/register'
    | '/settings/members'
    | '/settings/mypage'
    | '/settings/organization'
    | '/settings/report-types'
    | '/settings/setup-spots';
  id:
    | '__root__'
    | '/'
    | '/_with-sidebar'
    | '/user'
    | '/_with-sidebar/dashboard/'
    | '/_with-sidebar/report/'
    | '/_with-sidebar/tags/'
    | '/user/join/'
    | '/user/login/'
    | '/user/password/'
    | '/user/register/'
    | '/_with-sidebar/settings/members/'
    | '/_with-sidebar/settings/mypage/'
    | '/_with-sidebar/settings/organization/'
    | '/_with-sidebar/settings/report-types/'
    | '/_with-sidebar/settings/setup-spots/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  WithSidebarRoute: typeof WithSidebarRouteWithChildren;
  UserRoute: typeof UserRouteWithChildren;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  WithSidebarRoute: WithSidebarRouteWithChildren,
  UserRoute: UserRouteWithChildren,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_with-sidebar",
        "/user"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_with-sidebar": {
      "filePath": "_with-sidebar.tsx",
      "children": [
        "/_with-sidebar/dashboard/",
        "/_with-sidebar/report/",
        "/_with-sidebar/tags/",
        "/_with-sidebar/settings/members/",
        "/_with-sidebar/settings/mypage/",
        "/_with-sidebar/settings/organization/",
        "/_with-sidebar/settings/report-types/",
        "/_with-sidebar/settings/setup-spots/"
      ]
    },
    "/user": {
      "filePath": "user.tsx",
      "children": [
        "/user/join/",
        "/user/login/",
        "/user/password/",
        "/user/register/"
      ]
    },
    "/_with-sidebar/dashboard/": {
      "filePath": "_with-sidebar/dashboard/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/_with-sidebar/report/": {
      "filePath": "_with-sidebar/report/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/_with-sidebar/tags/": {
      "filePath": "_with-sidebar/tags/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/user/join/": {
      "filePath": "user/join/index.tsx",
      "parent": "/user"
    },
    "/user/login/": {
      "filePath": "user/login/index.tsx",
      "parent": "/user"
    },
    "/user/password/": {
      "filePath": "user/password/index.tsx",
      "parent": "/user"
    },
    "/user/register/": {
      "filePath": "user/register/index.tsx",
      "parent": "/user"
    },
    "/_with-sidebar/settings/members/": {
      "filePath": "_with-sidebar/settings/members/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/_with-sidebar/settings/mypage/": {
      "filePath": "_with-sidebar/settings/mypage/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/_with-sidebar/settings/organization/": {
      "filePath": "_with-sidebar/settings/organization/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/_with-sidebar/settings/report-types/": {
      "filePath": "_with-sidebar/settings/report-types/index.tsx",
      "parent": "/_with-sidebar"
    },
    "/_with-sidebar/settings/setup-spots/": {
      "filePath": "_with-sidebar/settings/setup-spots/index.tsx",
      "parent": "/_with-sidebar"
    }
  }
}
ROUTE_MANIFEST_END */
