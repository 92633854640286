import { notificationsQueryOptions } from '@/app/common/hooks/noti-query';
import { handleMessage, requestPermission } from '@/firebase-notification';
import PushAlarm from '@components/common/alarm/PushAlarm';
import Header from '@components/common/header/Header';
import SideBar from '@components/common/sidebar/SideBar';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_with-sidebar')({
  component: RouteComponent,
  loader: ({ context: { queryClient } }) => {
    queryClient.ensureInfiniteQueryData(
      notificationsQueryOptions({ page: 1, per_page: 10 })
    );
    requestPermission();
    handleMessage();
  },

  beforeLoad: async () => {
    const isLogin = true;
    if (!isLogin) {
      throw redirect({
        to: '/user/login',
        replace: true,
      });
    }
  },
});

function RouteComponent() {
  return (
    <>
      <div className="size-full max-w-screen overflow-hidden antialiased">
        <div className="mx-auto flex h-screen max-w-full min-w-[1024px] justify-center">
          <SideBar />
          <div className="max-w-wrap relative flex size-full flex-col">
            {/* Header */}
            <Header />
            <div className="flex size-full grow overflow-hidden">
              <main className="scrollbar-custom bg-bg h-full flex-grow overflow-scroll">
                <Outlet />
              </main>
            </div>
          </div>
        </div>
      </div>
      <PushAlarm />
    </>
  );
}
