import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  component: HomeComponent,
  beforeLoad: async () => {
    const isLogin = true;
    if (!isLogin) {
      throw redirect({
        to: '/user/login',
      });
    } else {
      throw redirect({
        to: '/dashboard',
      });
    }
  },
});

function HomeComponent() {
  return <Outlet />;
}
