import { getNotifications } from '@services/api-client';
import { infiniteQueryOptions } from '@tanstack/react-query';

// 알림 조회
export const notificationsQueryOptions = (params: {
  page: number;
  per_page: number;
}) =>
  infiniteQueryOptions({
    queryKey: ['notifications', params],
    queryFn: ({ pageParam }) =>
      getNotifications({ page: pageParam, per_page: params.per_page }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < params.per_page) return undefined;
      return allPages.length + 1;
    },
  });
