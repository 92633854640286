import {
  getReport,
  getReports,
  getReportTypes,
  ReportsQuery,
} from '@services/api-client';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

export const reportsQueryOptions = (query: ReportsQuery) =>
  queryOptions({
    queryKey: ['reports', query],
    queryFn: () => getReports(query),
    placeholderData: keepPreviousData,
  });

export const reportsInQueryOptions = (query: ReportsQuery) =>
  infiniteQueryOptions({
    queryKey: ['reports', 'infinite', query],
    queryFn: ({ pageParam }) =>
      getReports({
        ...query,
        page: pageParam,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.reports.length < query.per_page) return undefined;
      return allPages.length + 1;
    },
  });

export const reportQueryOptions = (id: number) =>
  queryOptions({
    queryKey: ['reports', id],
    queryFn: () => getReport(id),
    enabled: Boolean(id),
  });

export const reportTypeQueryOptions = () =>
  queryOptions({
    queryFn: getReportTypes,
    queryKey: ['report', 'types'],
  });
