import { getSetupSpotsOrg, SetupSpotsOrgQuery } from '@services/api-client';
import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';

export const setupSpotsInfinityOptions = (query: SetupSpotsOrgQuery) =>
  infiniteQueryOptions({
    queryKey: ['setup-spots', 'infinite', query],
    queryFn: ({ pageParam }) => getSetupSpotsOrg({ ...query, page: pageParam }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length < query.per_page) return undefined;
      return allPages.length + 1;
    },
  });

export const setupSpotQueryOptions = (query: SetupSpotsOrgQuery) =>
  queryOptions({
    queryKey: ['setup-spots', query],
    queryFn: () => getSetupSpotsOrg(query),
    placeholderData: keepPreviousData,
  });
