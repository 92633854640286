import UserTextBox from '@/app/user/components/UserTextBox';
import useJoin from '@/app/user/hooks/useJoin';
import { signUpSchema } from '@/app/user/schema';
import Button from '@components/basic/buttons/Button';
import LinkText from '@components/basic/link/LinkText';
import { Checkbox } from '@components/ui/checkbox';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFileRoute } from '@tanstack/react-router';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const searchSchema = Yup.object({
  organization_id: Yup.number()
    .transform((value, originalValue) =>
      originalValue === undefined || originalValue === ''
        ? undefined
        : Number(originalValue)
    )
    .nullable()
    .optional(),
});
// Start of Selection
export const Route = createFileRoute('/user/join/')({
  component: RouteComponent,
  validateSearch: (search) =>
    searchSchema.validateSync(search, { stripUnknown: true }),
});

function RouteComponent() {
  const { organization_id } = Route.useSearch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(signUpSchema),
  });
  const { t } = useTranslation();

  const { mutate } = useJoin();

  const isValid = methods.formState.isValid;
  const handleJoin = () => {
    const values = methods.getValues();
    mutate({
      name: values.name,
      email: values.email,
      password: values.password,
      organization_id: organization_id as number,
    });
  };
  return (
    <div>
      <h2 className="text-title/normal mb-15 font-bold">{t('join')}</h2>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleJoin)}
          className="flex flex-col gap-1"
        >
          <UserTextBox
            label={t('name')}
            placeholder={t('name_placeholder')}
            name="name"
            type="name"
          />
          <UserTextBox
            label={t('email')}
            placeholder="email@address.com"
            name="email"
            type="email"
          />
          <UserTextBox
            label={t('password')}
            placeholder={t('password_placeholder')}
            name="password"
            type="password"
          />
          <UserTextBox
            label={t('password_check')}
            placeholder={t('password_check_placeholder')}
            name="confirmPassword"
            type="password"
          />

          <div className="mt-5">
            <div className="mb-15 flex items-center gap-[14px]">
              <Controller
                name="check"
                control={methods.control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox checked={value} onCheckedChange={onChange} />
                )}
              />

              <div className="text-base/(--spacing-30px) text-[#6e6e6e]">
                <span>I agree to the</span>{' '}
                <LinkText
                  to=""
                  target="_blank"
                  className="text-lg/(--spacing-30px)"
                >
                  Terms of Service
                </LinkText>{' '}
                and{' '}
                <LinkText
                  to=""
                  target="_blank"
                  className="text-lg/(--spacing-30px)"
                >
                  Privacy
                </LinkText>{' '}
                Policy
              </div>
            </div>
            <Button type="submit" disabled={!isValid}>
              {t('join')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
