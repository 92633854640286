import { reportTypeQueryOptions } from '@/app/report/hooks/report-query';
import RegisterReportType from '@app/report-types/components/RegisterReportType';
import ReportTypeTable from '@app/report-types/components/ReportTypeTable';
import DefaultLayout from '@components/layout/DefaultLayout';
import { createFileRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const Route = createFileRoute('/_with-sidebar/settings/report-types/')({
  component: RouteComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient.ensureQueryData(reportTypeQueryOptions()),
});

function RouteComponent() {
  const { t } = useTranslation();
  return (
    <DefaultLayout title={t('report_types_title')}>
      <RegisterReportType />
      <ReportTypeTable />
    </DefaultLayout>
  );
}
